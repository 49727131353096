import React from "react"
import ReactMarkdown from "react-markdown"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../../../components/Layout"
import Hero from "../../../components/Hero"
import Seo from "../../../components/Seo"

export default function Blog({ data }) {
  const blog = data.strapiBlogs
  const keywords = blog.keywords

  return (
    <Layout>
      <Seo
        title={blog.title}
        description={blog.excerpt}
        remoteImage={blog.image.url}
        keywords={keywords}
        article
      />
      <Hero
        background={
          <GatsbyImage
            // alt={}
            image={getImage(blog.image.localFile)}
            className="h-full w-full"
          />
        }
        overlay="bg-opacity-50"
        content={<h1 className="sm:w-2/3 !normal-case">{blog.title}</h1>}
      />
      <section className="wrapper container max-w-5xl py-4 sm:p-16">
        <ReactMarkdown className="blog">{blog.content}</ReactMarkdown>
        <div className="blog">
          <h2>
            The good news is that there is no cost to use Builder Finders. We
            are paid by the builder you have selected.
          </h2>
          <p>
            Building is an exciting time. It can also be a time of great anxiety
            if you choose the wrong builder. Did you know Builder Finders does
            not accept every builder. Many builders who apply to join the
            Builder Finders panel don’t make it. There is a 62 step vetting
            process for builders to join the panel. In addition, the Builder
            Finders team conducts comprehensive due diligence through reference
            checks, ASIC searches and CreditorWatch reports to ensure they are
            not insolvent. You may not be aware that in the last financial year
            just over 500 builders went bust. Worse still, right now there are
            builders in financial difficulty that are cutting corners to stay
            afloat. Fact is, a lot of builders will tell you what you want to
            hear. Engaging with one of these builders will invariably mean
            subpar construction of your home. No one wants that.
          </p>
          <h2>With Builder Finders, you are in safe hands.</h2>
          <p>
            Why risk it? The Builder Finders team have done all the background
            searches for you. We take pride in knowing our clients are dealing
            with some of Australia’s leading and award winning builders. We
            understand the services of a builder broker may be a new concept for
            you. It would be our pleasure to refer customers that have used our
            services in the past.
          </p>
          <div className="bg-secondary p-8 text-sm mt-4 italic">
            Builder Finders are independent builder brokers that assist
            everything builder related. We are driven by a passion to match the
            right builder with your unique property building needs. Best of all,
            there is no cost to you. Contact us now at{" "}
            <a href="tel: 1300422845" className="underline hover:no-underline">
              1300 4 A BUILDER (1300 422 845)
            </a>{" "}
            or send us a message{" "}
            <a
              href="/find-a-builder/contact-us"
              target="_blank"
              className="underline hover:no-underline"
            >
              here
            </a>
            .
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query blogPageQuery($slug: String!) {
    strapiBlogs(slug: { eq: $slug }) {
      slug
      title
      content
      image {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
        url
      }
      seo {
        metaTitle
        metaDescription
      }
      keywords {
        keyword
      }
    }
  }
`
